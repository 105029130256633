<template>
    <SuccessScreen
        titleKey="wizard.success.title"
        messageKey="wizard.success.created-message"
        nextRouteName="home"
        buttonTextKey="ask-visit.success.button-text"
        :backNavigationSteps=3
    />
</template>

<script>
import SuccessScreen from '@/components/SuccessScreen.vue';

export default {
    name: 'PublicationSuccess',
    components: {
        SuccessScreen
    }
}
</script>